import { useScriptTag } from '@vueuse/core'

export function useVastImpressions() {
  const config = useEnvConfig()
  const isReady = useState('vast-impressions-isReady', () => false)
  const impressionsCache = useState('vast-impressions-cache', () => ([]))

  const initializing = useState('vast-impression-initializing', () => false)

  if (import.meta.client && !isReady.value && !initializing.value) {
    initializing.value = true
    // append vast impressions
    useScriptTag(config.value.vastImpressions.url, () => {
      isReady.value = true
    })
  }

  const trigger = (data) => {
    if (!isReady.value) {
      impressionsCache.value.push(data)
      return
    }

    // eslint-disable-next-line no-new
    new window.VastImpressions({
      apikey: config.value.vastImpressions.apikey,
      category: 'cars',
      impression_env: config.value.VAST_ENVIRONMENT,
      ...data,
    })
  }

  watch(isReady, () => {
    impressionsCache.value.forEach((data, i) => {
      trigger(data)
      impressionsCache.value.splice(i, 1)
    })
    impressionsCache.value = []
  }, {
    once: true,
  })

  return {
    trigger,
  }
}
